html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

/* carousel */
.carousel.carousel-slider {
  overflow: visible;
}

.carousel .control-dots {
  bottom: -50px;
}

/* slick */
/* .slick-slider.slick-initialized{
  position: inherit;
}

.slick-slider.slick-list{
  position: inherit;
} */

.slick-slide > div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.Toastify .Toastify__toast {
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  border: solid 1px transparent;
  padding: 15px;
}

.Toastify__toast-body button,
.Toastify__toast--error button,
.Toastify__toast--success button {
  color: #111111;
}

.Toastify .Toastify__toast--success {
  background: #f8fffd;
  border-color: #84cfb8;
}

.Toastify .Toastify__toast-body {
  font-size: 14px;
  line-height: 16px;
  color: #111111;
  margin: 0;
}

.Toastify .Toastify__toast--error {
  background: #ffe6e6;
  border-color: #ebbbbb;
}

@media (max-width: 600px) {
  .Toastify .Toastify__toast,
  .Toastify .Toastify__toast--success,
  .Toastify .Toastify__toast-body,
  .Toastify .Toastify__toast--error {
    border-radius: 0px;
  }
}
